import { IconType } from "react-icons";
import { NavLink } from "react-router-dom";
import {
  FaLaptopCode,
  FaLinkedinIn,
  FaGithub,
  FaStackOverflow,
  FaFacebook,
} from "react-icons/fa";
import Icon from "@components/Icon.component";
import footerItems, { FooterLink } from "@data/footerLinks.data";

type SocialMediaItem = {
  title: string;
  icon: IconType;
  url: string;
};

interface FooterProps {
  socialMediaItems: SocialMediaItem[];
  developerDetails: {
    name: string;
    url: string;
  };
}

let linkedInSMItem: SocialMediaItem = {
  title: "Linkedin",
  icon: FaLinkedinIn,
  url: "https://www.linkedin.com/in/kanakamedala-rajesh/",
};

let githubSMItem: SocialMediaItem = {
  title: "Github",
  icon: FaGithub,
  url: "https://github.com/kanakamedala-rajesh",
};

let stackOverfowSMItem: SocialMediaItem = {
  title: "Stack Overflow",
  icon: FaStackOverflow,
  url: "https://stackoverflow.com/users/2172637/0x52616a657368",
};

let facebookSMItem: SocialMediaItem = {
  title: "facebook",
  icon: FaFacebook,
  url: "https://www.facebook.com/kanakamedala.rajesh",
};

function Footer(props: FooterProps) {
  return (
    <footer className="bg-white p-6 dark:bg-gray-800 md:p-12">
      <hr className="my-16 border-gray-200 dark:border-gray-700 sm:my-4 sm:mx-auto lg:my-8" />
      <div className="md:flex md:justify-between">
        <Icon name={"JSON"} icon={FaLaptopCode} linkURL={"/about"} />
        <nav className="-mx-5 -my-2 flex flex-wrap justify-center">
          {footerItems.map((item: FooterLink) => {
            return (
              <div
                className="px-5 py-2"
                key={`footer-navlink-div-${item.name}`}
              >
                <NavLink
                  key={`footer-navlink-${item.name}`}
                  to={item.url}
                  className="text-base leading-6 text-gray-500 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-100"
                >
                  {item.name}
                </NavLink>
              </div>
            );
          })}
        </nav>
      </div>
      <div className="my-12 sm:flex sm:items-center sm:justify-between">
        <span className="text-sm text-gray-500 dark:text-gray-200 sm:text-center">
          © {new Date().getFullYear() + " "}
          <a
            href={props.developerDetails.url}
            target={`_blank`}
            className="hover:underline"
          >
            {props.developerDetails.name}
          </a>
          ™. All Rights Reserved.
        </span>
        <div className="mt-4 flex space-x-6 sm:mt-0 sm:justify-center">
          {props.socialMediaItems.map((item: SocialMediaItem) => {
            return (
              <a
                key={`social-media-item-${item.title}`}
                href={item.url}
                target={`_blank`}
                className="text-gray-500 hover:text-gray-900 dark:hover:text-white"
              >
                <item.icon title={item.title} className="h-5 w-5" />
              </a>
            );
          })}
        </div>
      </div>
    </footer>
  );
}

export default Footer;

export { facebookSMItem, stackOverfowSMItem, githubSMItem, linkedInSMItem };
