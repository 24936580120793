type FooterLink = {
  name: string;
  url: string;
};

const footerItems: FooterLink[] = [
  { name: "Tutorials", url: "/" },
  { name: "Users", url: "/users" },
];

export type { FooterLink };
export default footerItems;
