import { ApolloError, useQuery } from "@apollo/client";

import Loader from "@components/Loader.component";
import TutorialCard from "@components/TutorialCard.component";
import AnimatedPage from "@components/AnimatedPage.component";

import { NetworkRequestState as NetworkRequestStatus } from "@entities/NetworkStates";
import Tutorial from "@entities/Tutorial.entity";

import { getTutorialsQuery } from "@schemas/Queries.schema";

interface TutorialsList {
  tutorials: Tutorial[];
}

interface TutorialsProps {}

interface TutorialsQueryItems {
  tutorials: Tutorial[];
  networkRequestStatus: NetworkRequestStatus;
  error?: ApolloError;
}

export function TutorialsService(): TutorialsQueryItems {
  const { loading, data, error } = useQuery<TutorialsList, TutorialsProps>(
    getTutorialsQuery,
    {}
  );

  if (loading) {
    return {
      tutorials: {},
      networkRequestStatus: NetworkRequestStatus.LOADING,
    } as TutorialsQueryItems;
  }

  if (error) {
    return {
      tutorials: {},
      networkRequestStatus: NetworkRequestStatus.ERROR,
      error: error,
    } as TutorialsQueryItems;
  }

  return {
    tutorials: data?.tutorials,
    networkRequestStatus: NetworkRequestStatus.SUCCESS,
  } as TutorialsQueryItems;
}

export default function AllTutorials() {
  const { loading, data } = useQuery<TutorialsList, TutorialsProps>(
    getTutorialsQuery,
    {}
  );
  return (
    <AnimatedPage title="Tutorials">
      {loading ? (
        <Loader />
      ) : (
        <div className="grid grid-cols-1 gap-4 py-8 px-4 md:grid-cols-2 md:gap-8 lg:grid-cols-3 lg:gap-16 xl:grid-cols-4 xl:gap-20">
          {data &&
            data.tutorials.map((tutorial, index) => {
              return (
                <TutorialCard
                  key={index}
                  tutorial={tutorial}
                  index={index}
                  setActiveTutorial={function (
                    tutorial: Tutorial,
                    index: number
                  ): void {
                    throw new Error("Function not implemented.");
                  }}
                />
              );
            })}
        </div>
      )}
    </AnimatedPage>
  );
}
