import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "flowbite";
import "./index.css";
import { ThemeProvider } from "./components/ThemeContext";
import { apolloClient } from "./services/ApolloAPI.service";

import { ApolloProvider } from "@apollo/client";

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider initialTheme={null}>
      <ApolloProvider client={apolloClient}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ApolloProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
