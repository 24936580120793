const Loader = () => {
  let circleCommonClasses =
    "h-2.5 w-2.5 bg-current rounded-full bg-gray-800 dark:bg-gray-100";

  return (
    <div className="flex justify-center text-center">
      <div className={`${circleCommonClasses} mr-1 animate-bounce`} />
      <div className={`${circleCommonClasses} mr-1 animate-bounce-200`} />
      <div className={`${circleCommonClasses} animate-bounce-400`} />
    </div>
  );
};

export default Loader;
