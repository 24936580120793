import React from "react";
import Loadable from "react-loadable";
import Loader from "@components/Loader.component";

export default class Loading extends React.Component<Loadable.LoadingComponentProps> {
  render() {
    return (
      <div>
        {this.props.error || this.props.timedOut ? (
          <>
            Unexpected error while loading your content! Please try again later.
          </>
        ) : this.props.pastDelay ? (
          <Loader />
        ) : (
          <></>
        )}
      </div>
    );
  }
}
