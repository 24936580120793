import { motion } from "framer-motion";
import { useContext } from "react";
import { FaSun, FaMoon } from "react-icons/fa";
import { ThemeContext } from "@components/ThemeContext";

export default function ThemeToggle() {
  const state = useContext(ThemeContext);

  const spring = {
    type: "spring",
    stiffness: 900,
    damping: 50,
  };

  return (
    <div
      onClick={() => {
        state?.dispatcher(state.themeValue === "dark" ? "light" : "dark");
      }}
      className={`flex-start ml-4 flex h-10 w-20 rounded-3xl bg-zinc-100 shadow-inner hover:cursor-pointer dark:bg-zinc-700 ${
        state?.themeValue === "dark" && "place-content-end"
      }`}
    >
      <motion.div
        className="flex h-10 w-10 items-center justify-center rounded-full"
        layout
        transition={spring}
      >
        <motion.div whileTap={{ rotate: 360 }}>
          {state?.themeValue !== "dark" ? (
            <FaSun className="h-7 w-7 text-red-500" />
          ) : (
            <FaMoon className="h-7 w-7 text-blue-500" />
          )}
        </motion.div>
      </motion.div>
    </div>
  );
}
