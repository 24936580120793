import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Loadable from "react-loadable";

import { usersNavBarItem } from "@data/navbar.data";

import AllTutorials from "@services/Tutorials.service";

import Navbar from "@components/Navbar.component";
import Loading from "@components/Loading.component";
import {
  linkedInSMItem,
  githubSMItem,
  stackOverfowSMItem,
  facebookSMItem,
} from "@components/Footer.component";

import "@/App.css";

const UsersLoadableComponent = Loadable({
  loader: () => import("@components/Users.component"),
  loading: Loading,
});

const FooterLoadableComponent = Loadable({
  loader: () => import("@components/Footer.component"),
  loading: Loading,
});

function App() {
  const location = useLocation();
  return (
    <>
      <Navbar navbarItem={usersNavBarItem} />
      <div className="bg-white px-12 dark:bg-gray-800">
        <AnimatePresence exitBeforeEnter>
          <Routes key={location.pathname} location={location}>
            <Route path="/" element={<AllTutorials />} />
            <Route path="/users" element={<UsersLoadableComponent />} />
          </Routes>
        </AnimatePresence>
      </div>
      <FooterLoadableComponent
        socialMediaItems={[
          linkedInSMItem,
          githubSMItem,
          stackOverfowSMItem,
          facebookSMItem,
        ]}
        developerDetails={{
          name: "Rajesh Kanakamedala",
          url: "https://rajesh.venkatasudha.com/",
        }}
      />
    </>
  );
}

export default App;
