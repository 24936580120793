import { gql } from "@apollo/client";

export const getTutorialsQuery = gql`
  query getTutorials {
    tutorials {
      id
      description
      title
      published
    }
  }
`;

export const getUsersQuery = gql`
  query getUsers {
    users {
      id
      name
      phone
      email
      address {
        geo {
          latitude
          longitude
        }
      }
      website
    }
  }
`;

export const getAlbumsQuery = gql`
  query getAlbums {
    albums {
      id
      userId
      title
    }
  }
`;

export const getPhotosQuery = gql`
  query getPhotos {
    photos {
      id
      albumId
      title
      url
      thumbnailUrl
    }
  }
`;

export const getPostsQuery = gql`
  query getPosts {
    posts {
      id
      userId
      title
      body
    }
  }
`;

export const getCommentsQuery = gql`
  query getComments {
    comments {
      id
      postId
      name
      email
      body
    }
  }
`;

export const getTodosQuery = gql`
  query getTodos {
    todos {
      id
      userId
      title
      completed
    }
  }
`;
