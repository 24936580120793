import { BiWifi, BiWifiOff } from "react-icons/bi";
import { useNetworkState } from "react-use";

function NetworkConnectionIcon() {
  const state = useNetworkState();
  const iconSizeClasses = "h-8 w-8 md:h-9 md:w-9";

  return (
    <div className="ml-5 flex items-center">
      <>
        {state.online ? (
          <BiWifi
            className={`text-green-600 dark:text-green-500 ${iconSizeClasses}`}
            title="Online"
          />
        ) : (
          <BiWifiOff
            className={`text-red-600 dark:text-red-500 ${iconSizeClasses}`}
            title="Offline, Please check internet connection"
          />
        )}
      </>
    </div>
  );
}

export default NetworkConnectionIcon;
