import React from "react";
import { useTitle } from "react-use";
import { motion } from "framer-motion";

import { pageTransitionAnimations } from "@utils/FramerMotionAnimations";

type Props = {
  title: string;
  children?: React.ReactNode;
};

export default function AnimatedPAge(props: Props) {
  useTitle(props.title);
  return (
    <motion.div
      variants={pageTransitionAnimations}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: 0.7 }}
    >
      {props.children}
    </motion.div>
  );
}
