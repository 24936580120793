import React from "react";

const getInitialTheme = () => {
  if (typeof window !== "undefined" && window.localStorage) {
    const storedPrefs = window.localStorage.getItem("current-theme");
    if (typeof storedPrefs === "string") {
      return storedPrefs;
    }
    if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
      return "dark";
    }
  }
  return "light";
};

export const ThemeContext = React.createContext<null | {
  themeValue: string;
  dispatcher: React.Dispatch<React.SetStateAction<string>>;
}>(null);

type Props = {
  initialTheme: string | null;
  children: React.ReactNode;
};

export const ThemeProvider = (props: Props) => {
  const [theme, setTheme] = React.useState(getInitialTheme);
  const checkTheme = (existing: string) => {
    const root = window.document.documentElement;
    const isDark = existing === "dark";

    root.classList.remove(isDark ? "light" : "dark");
    root.classList.add(existing);

    localStorage.setItem("current-theme", existing);
  };

  if (props.initialTheme) {
    checkTheme(props.initialTheme);
  }

  React.useEffect(() => {
    checkTheme(theme);
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ themeValue: theme, dispatcher: setTheme }}>
      {props.children}
    </ThemeContext.Provider>
  );
};
