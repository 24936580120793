import { Link } from "react-router-dom";
import { IconType } from "react-icons";

interface IconProps {
  name: string;
  icon: IconType;
  linkURL?: string;
}

function Icon(props: IconProps) {
  return (
    <Link
      to={"/"}
      className="flex items-center text-gray-700 hover:text-blue-700 dark:text-white"
    >
      <props.icon className="mr-2 h-8 w-8" />
      <span className="self-center whitespace-nowrap bg-clip-text text-2xl font-bold hover:bg-gradient-to-b hover:from-rose-400 hover:via-fuchsia-500 hover:to-indigo-500 hover:text-transparent dark:text-white">
        {props.name}
      </span>
    </Link>
  );
}

export default Icon;
