import { NavLink } from "react-router-dom";
import { FaLaptopCode, FaSearch, FaBars } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { useKey } from "react-use";

import Icon from "@components/Icon.component";
import ReadingBar from "@components/ReadingBar.component";
import NetworkConnectionIcon from "@components/NetworkConnection.component";
import ThemeToggle from "@components/ThemeToggle.component";

import { NavBarItem } from "@data/navbar.data";

type NavbarProps = {
  navbarItem: NavBarItem;
};

let activeLinkClassName =
  "block rounded bg-blue-700 py-2 pr-4 pl-3 text-xl font-bold text-white decoration-4 underline-offset-4 dark:text-white md:bg-transparent md:p-0 md:text-blue-700 md:underline";
let inActiveLinkClassName =
  "block border-b border-gray-100 py-2 pr-4 pl-3 text-xl text-gray-700 decoration-4 underline-offset-4 hover:bg-gray-50 hover:font-bold dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:border-0 md:p-0 md:hover:bg-transparent md:hover:text-blue-700 md:hover:underline md:dark:hover:bg-transparent md:dark:hover:text-white";

function Navbar(props: NavbarProps) {
  useKey("/", () => alert("Search functionality not yet implemented"));

  return (
    <nav className="sticky top-0 z-50 bg-white pt-2 shadow-md dark:bg-gray-800">
      <ReadingBar />
      <div className="container mx-auto flex flex-wrap items-center justify-between">
        <Icon name={"JSON"} icon={FaLaptopCode} linkURL={"/about"} />
        <div className="flex md:order-2">
          <div className="relative mr-3 hidden md:mr-0 md:block">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <FaSearch className="h-4 w-4 text-gray-500" />
            </div>
            <input
              type="text"
              id="email-adress-icon"
              className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2 pl-10 text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500 sm:text-sm"
              placeholder="press / to search"
            />
          </div>
          <ThemeToggle />
          <NetworkConnectionIcon />
          <button
            data-collapse-toggle="mobile-menu-3"
            type="button"
            className="ml-3 inline-flex items-center rounded-lg p-2 text-sm text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600 md:hidden"
            aria-controls="mobile-menu-3"
            aria-expanded="false"
          >
            <span className="sr-only">Open main menu</span>
            <FaBars className="h-6 w-6" />
            <MdClose className="hidden h-6 w-6" />
          </button>
        </div>
        <div
          className="hidden w-full items-center justify-between md:order-1 md:flex md:w-auto"
          id="mobile-menu-3"
        >
          <ul className="mt-4 flex flex-col md:mt-0 md:flex-row md:space-x-8 md:text-sm md:font-medium">
            <li>
              <NavLink
                to={"/"}
                className={({ isActive }) =>
                  isActive ? activeLinkClassName : inActiveLinkClassName
                }
                aria-current="page"
              >
                Tutorials
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"/users"}
                className={({ isActive }) =>
                  isActive ? activeLinkClassName : inActiveLinkClassName
                }
              >
                Users
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
      <hr className="my-16 border-gray-200 dark:border-gray-700 sm:mx-auto lg:my-8" />
    </nav>
  );
}

export default Navbar;
