enum NavBarItemEnum {
  ALBUMS,
  COMMENTS,
  PHOTOS,
  POSTS,
  TODOS,
  USERS,
  NONE,
}

type NavBarItem = {
  _id: number;
  navbarItem: NavBarItemEnum;
  tabItems: NavBarItemEnum[];
  defaultTabItem?: NavBarItemEnum;
  url: string;
};

const usersNavBarItem: NavBarItem = {
  _id: 1,
  navbarItem: NavBarItemEnum.USERS,
  tabItems: [NavBarItemEnum.PHOTOS],
  url: "/users",
};

const albumsNavBarItem: NavBarItem = {
  _id: 2,
  navbarItem: NavBarItemEnum.USERS,
  tabItems: [NavBarItemEnum.ALBUMS, NavBarItemEnum.POSTS, NavBarItemEnum.TODOS],
  defaultTabItem: NavBarItemEnum.ALBUMS,
  url: "/albums",
};

const postsNavBarItem: NavBarItem = {
  _id: 3,
  navbarItem: NavBarItemEnum.USERS,
  tabItems: [NavBarItemEnum.COMMENTS],
  url: "/posts",
};

const defaultNavBarItem: NavBarItem = {
  _id: 0,
  navbarItem: NavBarItemEnum.NONE,
  tabItems: [],
  url: "/",
};

export {
  NavBarItemEnum,
  usersNavBarItem,
  albumsNavBarItem,
  postsNavBarItem,
  defaultNavBarItem,
};
export type { NavBarItem };
