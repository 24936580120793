import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { motion } from "framer-motion";

import Tutorial from "@entities/Tutorial.entity";
import {
  cardContainerVariant,
  cardVariant,
} from "@utils/FramerMotionAnimations";

interface TutorialCardProps {
  setActiveTutorial(tutorial: Tutorial, index: number): void;
  tutorial: Tutorial;
  index: number;
}

export default function TutorialCard(props: TutorialCardProps) {
  return (
    <motion.div
      key={props.index}
      className="min-w-full cursor-pointer rounded-2xl bg-gray-50 p-1 shadow-xl transition-all duration-300 ease-in-out hover:scale-110 hover:bg-gradient-to-b hover:from-rose-400 hover:via-fuchsia-500 hover:to-indigo-500 dark:bg-gray-900"
      onClick={() => props.setActiveTutorial(props.tutorial, props.index)}
      variants={cardContainerVariant}
      initial="hidden"
      animate="visible"
      whileHover="hover"
      whileTap="tap"
    >
      <motion.div
        className="group block h-full rounded-xl bg-gray-50 p-6 dark:bg-gray-900 sm:p-8"
        key={"item-" + props.index}
        variants={cardVariant}
      >
        <div className="mt-16 sm:pr-8">
          <h5 className="text-2xl font-bold text-gray-900 dark:text-gray-50">
            {props.tutorial.title}
          </h5>

          <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
            {props.tutorial.description}
          </p>
        </div>
        <div className="mt-16 inline-flex items-center text-blue-700">
          <p className="bg-clip-text text-lg font-bold group-hover:bg-gradient-to-b group-hover:from-rose-400 group-hover:via-fuchsia-500 group-hover:to-indigo-500 group-hover:text-transparent">
            Modify
          </p>
          <HiOutlineArrowNarrowRight className="ml-3 h-6 w-6 transform transition-transform group-hover:translate-x-3" />
        </div>
      </motion.div>
    </motion.div>
  );
}
